import { i18n } from '@/libs/i18n'
export default [
  {
    header: 'Golf',
    permission: ['MTE01', 'MBK01', 'MBR01', 'MAU01', 'MME01','MBKWL01','MBKR01'],
    // icon: 'HomeIcon',
    children: [
      {
        title: 'Tee time',
        route: 'tee-sheet',
        permission: ['MTE01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_golf_create_booking'),
        route: 'booking',
        permission: ['MBK01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_golf_waiting_list'),
        route: 'WatingList',
        permission: ['MBKWL01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_golf_booking_retail'),
        route: 'booking-retail',
        permission: ['MBR01'],
        // icon: 'ActivityIcon',
      },
      {
        title: 'Kiểm toán',
        route: '',
        permission: ['MAU01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_golf_guest_management'),
        route: 'members',
        permission: ['MME01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_golf_revert_booking_checkout'),
        route: 'revert',
        permission: ['MBKR01'],
        // icon: 'ActivityIcon',
      }
    ],
  },
  {
    header: i18n.tc('nav_driving_range'),
    permission: ['MDR01', 'MAU02'],
    // icon: 'HomeIcon',
    children: [
      {
        title: 'Driving range',
        route: 'booking-driving-range',
        permission: ['MDR01'],
        // icon: 'ActivityIcon',
      },
      {
        title: 'Kiểm toán',
        route: '',
        permission: ['MAU02'],
        // icon: 'ActivityIcon',
      },
    ],
  },
  {
    header: 'Starter',
    permission: ['MST01'],
    // icon: 'HomeIcon',
    children: [
      {
        title: i18n.tc('nav_starter_coordinator'),
        route: 'starter',
        permission: ['MST01'],
        // icon: 'ActivityIcon',
      },
    ],
  },
  {
    header: 'Caddy',
    permission: ['MCA01', 'MCA02'],
    // icon: 'HomeIcon',
    children: [
      {
        title: i18n.tc('nav_caddy_caddy_evaluation'),
        route: 'caddy-evaluation',
        permission: ['MCA01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_Caddy_caddy_calendar'),
        route: 'caddy-calendar',
        permission: ['MCA02'],
        // icon: 'ActivityIcon',
      },
    ],
  },
  {
    header: i18n.tc('nav_locker'),
    permission: ['MLK01', 'MLK02'],
    // icon: 'HomeIcon',
    children: [
      {
        title: i18n.tc('nav_locker_management'),
        route: 'locker-setting',
        permission: ['MLK01'],
      },
      {
        title: i18n.tc('nav_locker_inventory'),
        route: 'locker-inventory',
        permission: ['MLK02'],
      },
    ],
  },
  {
    header: i18n.tc('nav_tournament'),
    permission: ['MTOU01'],
    // icon: 'HomeIcon',
    title: i18n.tc('nav_tournament'),
    route: 'Tournament',
  },
  {
    header: i18n.tc('nav_report'),
    permission: ['MRP01','MRRCC00','MRLPD01'],
    // icon: 'HomeIcon',
    children: [
      {
        title: i18n.tc('nav_report_list_report'),
        route: 'report',
        permission: ['MRP01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_report_data_processing'),
        route: 'DataProcessing',
        permission: ['MRRCC00'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_report_data_processing_history'),
        route: 'DataProcessingHistory',
        permission: ['MRLPD01'],
        // icon: 'ActivityIcon',
      }
    ],
  },
  {
    header: i18n.tc('nav_list_booking'),
    permission: ['MBK02'],
    // icon: 'HomeIcon',
    title: i18n.tc('nav_list_booking'),
    route: 'bookinglist',
  },
  {
    header: i18n.tc('nav_invoice'),
    permission: ['MIV01','MIV02'],
    // icon: 'HomeIcon',
    children: [
      {
        title: i18n.tc('nav_invoice_list_payment'),
        route: 'list-payment',
        permission: ['MIV01'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_invoice_list_invoice'),
        route: 'list-invoice',
        permission: ['MIV02'],
        // icon: 'ActivityIcon',
      },
      {
        title: i18n.tc('nav_invoice_config_form_serial'),
        route: 'config-form-serial',
        permission: ['MIV02'],
        // icon: 'ActivityIcon',
      }
    ],
  },
  {
    header: 'Voucher',
    title: 'Voucher',
    permission: ['MVC01'],
    route: 'list-voucher',
    // icon: 'HomeIcon',
  },
  {
    header: i18n.tc('nav_configuration'),
    permission: ['MCO01', 'MRA01', 'MPA01', 'MMC01', 'MDR01', 'MCA03','MRO01','MRC01'],
    // icon: 'HomeIcon',
    route: 'update',
    children: [
      {
        title: i18n.tc('nav_configuration_general'),
        permission: ['MGC01','MRO01','MBO01'],
        route: '',
        // icon: 'ActivityIcon',
        children: [
          //     {
          //       title: 'Thời gian',
          //       route: '',
          //       permission: ['RTE01'],
          //       // icon: 'ActivityIcon',
          //     },
          //     {
          //       title: 'Thông báo',
          //       route: '',
          //       permission: ['RTE01'],
          //       // icon: 'ActivityIcon',
          //     },
          //     {
          //       title: 'Booking',
          //       route: '',
          //       permission: ['RTE01'],
          //       // icon: 'ActivityIcon',
          //     },
          {
            title: 'Golf class',
            route: 'golf-class',
            permission: ['MGC01'],
            // icon: 'ActivityIcon',
          },
          {
            title: 'Role',
            route: 'user',
            permission: ['MRO01'],
            // icon: 'ActivityIcon',
          },
          {
            title: i18n.tc('nav_system_parameters'),
            route: 'SystemParameters',
            permission: ['MBO01'],
          },
          //   permission: ['MGC01'],
          //   // icon: 'ActivityIcon',
          // },
        ],
      },
      {
        title: 'Golf',
        permission: ['MCO01', 'MRA01', 'MPA01', 'MMC01'],
        route: '',
        // icon: 'ActivityIcon',
        children: [
          {
            title: i18n.tc('nav_configuration_course'),
            route: 'course-setting',
            permission: ['MCO01'],
            // icon: 'ActivityIcon',
          },
          {
            title: 'Rates',
            route: 'rate-code',
            permission: ['MRA01'],
            // icon: 'ActivityIcon',
          },
          {
            title: 'Package',
            route: 'package',
            permission: ['MPA01'],
            // icon: 'ActivityIcon',
          },
          {
            title: i18n.tc('nav_configuration_member_card'),
            route: 'member-card',
            permission: ['MMC01'],
            // icon: 'ActivityIcon',
          },
        ],
      },
      {
        title: i18n.tc('nav_configuration_driving_range'),
        permission: ['MDR01'],
        route: '',
        // icon: 'ActivityIcon',
        children: [
          {
            title: i18n.tc('nav_configuration_driving_range_setting'),
            route: 'driving-range-setting',
            permission: ['MDR01'],
            // icon: 'ActivityIcon',
          },
          // {
          //   title: 'Rates',
          //   permission: ['RTE01'],
          //   route: '',
          //   // icon: 'ActivityIcon',
          // },
          // {
          //   title: 'Package',
          //   route: 'package-driving-range',
          //   permission: ['RTE01'],
          //   // icon: 'ActivityIcon',
          // },
        ],
      },
      {
        title: 'Caddy',
        permission: ['MCA03'],
        route: '',
        // icon: 'ActivityIcon',
        children: [
          {
            title: i18n.tc('nav_configuration_caddy'),
            route: 'caddy-setting',
            permission: ['MCA03'],
            // icon: 'ActivityIcon',
          },
        ],
      },
      {
        title: i18n.tc('nav_configuration_report'),
        permission: ['MRC01'],
        route: '',
        // icon: 'ActivityIcon',
        children: [
          {
            title: i18n.tc('nav_configuration_revenue'),
            route: 'RevenueCenter',
            permission: ['MRC01'],
            // icon: 'ActivityIcon',
          },
          // {
          //   title: 'Cấu hình nhanh',
          //   route: 'ListServiceRevenueCenter',
          //   permission: ['MRC01'],
          //   // icon: 'ActivityIcon',
          // }
        ],
      }
      // {
      //   title: 'Locker',
      //   permission: ['RTE01'],
      //   route: '',
      //   // icon: 'ActivityIcon',
      //   children: [
      //     {
      //       title: 'Quản lý tủ locker',
      //       route: '',
      //       permission: ['RTE01'],
      //       // icon: 'ActivityIcon',
      //     },
      //   ],
      // },
    ],
  },
]
